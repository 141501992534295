// Note that images for testimonials live inside of the Testimonial components (primaryTestimonial and secondaryTestimonial) and are already taken care of

export const Alexis = {
    name: "Alexis D.",
    company: "Morning After Club",
    position: "Director of Marketing",
    content: "Batch has become the fastest way for us to quickly sell through our products. We buy inventory much more confidently knowing we can sell out-of-stock anytime without having to rely on markdowns.",
}

export const Maegan = {
    name: "Meagan S.",
    company: "9th & Seventh",
    position: "Marketing Manager",
    content: "I knew my job would be much easier knowing exactly what customers are willing to pay, but we were blown away! On launch day, Batch sales exceeded our regular sales. We're big fans!",
}

export const Brie = {
    name: "Brie P.",
    company: "CakeMyBake",
    position: "Head of Ecommerce",
    content: "It's become my go-to solution for clearing out inventory, whether it be slow-movers, during the off-season or just to round-out the month, Batch has become an integral part of our marketing stack.",
}

export const Jayna = {
    name: "Jayna G.",
    company: "Sustainable Innovation Company",
    position: "Director of Digital",
    content: "I used this app to implement a 'pay what you can' option to the cart for a social enterprise. The support has been the best I've ever received from any app to date. The functionality works well, they're always improving and it's easy to customise. Highly recommend.",
}

export const content = (props) => {
    switch (props.person) {
        case ("alexis" || "Alexis"):
            return Alexis.content
        case ("maegan" || "Maegan"):
            return Maegan.content
        case ("brie" || "Brie"):
            return Brie.content
        case ("jayna" || "Jayna"):
            return Jayna.content
        default:
            return Alexis.content 
    }
}

export const name = (props) => {
    switch (props.person) {
        case ("alexis" || "Alexis"):
            return Alexis.name
        case ("maegan" || "Maegan"):
            return Maegan.name
        case ("brie" || "Brie"):
            return Brie.name
        case ("jayna" || "Jayna"):
            return Jayna.name
        default:
            return Alexis.name 
    }
}

export const company = (props) => {
    switch (props.person) {
        case ("alexis" || "Alexis"):
            return Alexis.company
        case ("maegan" || "Maegan"):
            return Maegan.company
        case ("brie" || "Brie"):
            return Brie.company
        case ("jayna" || "Jayna"):
            return Jayna.company
        default:
            return Alexis.company 
    }
}

export const position = (props) => {
    switch (props.person) {
        case ("alexis" || "Alexis"):
            return Alexis.position
        case ("maegan" || "Maegan"):
            return Maegan.position
        case ("brie" || "Brie"):
            return Brie.position
        case ("jayna" || "Jayna"):
            return Jayna.position
        default:
            return Alexis.position 
    }
}